import {mapMutations} from "vuex";

export default {
  name: "product-card",
  props: {
    data: {
      type: Object,
      default: ()=> {}
    }
  },
  components:{
  },
  data(){
    return{
    }
  },
  created() {

  },
  mounted() {
  },
  computed:{

  },

  methods:{
    ...mapMutations({
    })

  }
}